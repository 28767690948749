<script>
  export let category;

  export let difficulty;

  import Card from "./Card.svelte";
  import { onMount, createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  let vocSubset;
  let cards;

  vocSubset = shuffleArray(category.slice()).slice(0, difficulty);

  cards = shuffleArray([...vocSubset, ...vocSubset]);

  cards = cards.map((card) => ({
    ...card,
    isFlipped: false,
    foundTwin: false,
    id: Math.random().toString(36),
    audioFile: new Audio(card.audio),
  }));

  let a = -1;
  let locked = false;
  let matchAudio = new Audio("/assets/audio/match.mp3");

  function handleCardClicked(e) {
    if (locked) return;

    let b = e.detail.index;

    if (cards[b].foundTwin == true) {
      return;
    } else if (a == b) {
      return;
    } else if (a == -1) {
      cards[b].audioFile.play();
      a = b;
      flip(a);
    } else if (a != b) {
      locked = true;
      flip(b);

      if (cards[a].text == cards[b].text) {
        matchAudio.play();
        disable(a);
        disable(b);
        setTimeout(() => {
          a = -1;
        }, 1000);
      } else {
        cards[b].audioFile.play();
        setTimeout(() => {
          unFlip(a);
          unFlip(b);
          a = -1;
        }, 1000);
      }
    }

    setTimeout(() => {
      locked = false;
    }, 2000);

    checkIfDone();
  }

  function flip(card) {
    cards[card] = { ...cards[card], isFlipped: true };
  }

  function unFlip(card) {
    cards[card] = { ...cards[card], isFlipped: false };
  }

  function disable(card) {
    cards[card] = { ...cards[card], foundTwin: true };
  }

  function checkIfDone() {
    let isDone = true;

    cards.forEach((card) => {
      if (isDone) {
        isDone = card.foundTwin ? true : false;
      } else {
        isDone = false;
      }
    });

    if (isDone) {
      dispatch("done");
    }
  }

  function shuffleArray(array) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
</script>

<div
  class="board"
  class:easy={difficulty == 4}
  class:medium={difficulty == 6}
  class:hard={difficulty == 7}
>
  {#each cards as { id, img, text, audio, isFlipped }, index (id)}
    <Card
      {index}
      {img}
      {text}
      {audio}
      {isFlipped}
      on:clicked={handleCardClicked}
    />
  {/each}
</div>

<style>
  .board {
    display: flex;
    flex-wrap: wrap;
    perspective: 1200px;
    transform-style: preserve-3d;
  }

  .easy {
    width: 18em;
    height: 21em;
  }

  .medium {
    width: 18em;
    height: 28em;
  }

  .hard {
    width: 18em;
    height: 35em;
  }

  @media (min-width: 60em) {
    .easy {
      width: 48em;
      height: 28em;
    }

    .medium {
      width: 48em;
      height: 42em;
    }

    .hard {
      width: 72em;
      height: 42em;
    }
  }
</style>
