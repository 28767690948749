<script>
  import Board from "./components/Board.svelte";
  import letters from "./letters.js";
  import animals from "./animals.js";
  import objects from "./objects.js";

  let hideHome = false;
  let hideInfo = true;
  let hideCategory = true;
  let hideDifficulty = true;
  let hideGame = true;
  let hideDone = true;
  let ad = 0;
  let hideLanguageSwitch = false;
  let hideExitGame = true;
  let category = [];
  let difficulty = 0;

  let supportedLangs = ["de", "en"];

  let activeLanguage =
    supportedLangs.indexOf(navigator.language.substr(0, 2)) == -1
      ? "en"
      : navigator.language.substr(0, 2);

  let translations = {
    back: {
      de: "Zurück",
      en: "Go back",
    },
    newGame: {
      de: "Neues Spiel",
      en: "New Game",
    },
    install: {
      de: "Installieren",
      en: "Install",
    },
    info: {
      de: "Info",
      en: "About",
    },
    letters: {
      de: "Buchstaben",
      en: "Letters",
    },
    animals: {
      de: "Tiere",
      en: "Animals",
    },
    objects: {
      de: "Gegenstände",
      en: "Objects",
    },
    easy: {
      de: "Einfach",
      en: "Easy",
    },
    medium: {
      de: "Mittel",
      en: "Medium",
    },
    hard: {
      de: "Schwer",
      en: "Hard",
    },
  };
  function changeLanguage() {
    let i = supportedLangs.indexOf(activeLanguage) + 1;
    i = i == supportedLangs.length ? 0 : i;
    activeLanguage = supportedLangs[i];
  }

  // Prevent the app from closing when pressing the back button on mobile
  let t;

  window.addEventListener("load", () => {
    t = Date.now();
    window.history.pushState({ noBackExitsApp: true }, "");
  });

  window.addEventListener("popstate", (event) => {
    if (event.state && event.state.noBackExitsApp) {
      if (Date.now() - t > 1000) {
        window.history.pushState({ noBackExitsApp: true }, "");
        t = Date.now();
      }
    }
  });

  let deferredPrompt = null;

  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    deferredPrompt = e;
  });

  async function install() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("PWA has been installed");
        } else {
          console.log("User chosen to not install PWA");
        }

        deferredPrompt = null;
      });
    }
  }

  function setCategory(cat) {
    hideCategory = true;
    hideDifficulty = false;
    category = cat;
  }
  function setDifficulty(dif) {
    difficulty = dif;
    hideDifficulty = true;
    hideGame = false;
  }

  function handleDone(event) {
    setTimeout(() => {
      hideGame = true;
      hideDone = false;
      hideExitGame = true;
      let cheer = new Audio("/assets/audio/cheer.mp3");
      cheer.play();
    }, 2000);
  }

  async function registerServiceWorker() {
    if ("serviceWorker" in navigator) {
      await navigator.serviceWorker.register("sw.js");
      console.log("[SW] Service worker has been registered");
    }
  }
</script>

<svelte:head>
  <title>MI Memory</title>
</svelte:head>

<svelte:window on:load={registerServiceWorker} />

<div class="container">
  <p
    class="exitGame"
    class:-hidden={hideExitGame}
    on:click={() => {
      hideExitGame = true;
      hideGame = true;
      hideDifficulty = false;
      hideLanguageSwitch = false;
    }}
  >
    {translations.back[activeLanguage]}
  </p>

  <p
    class="langSwitcher"
    class:-hidden={hideLanguageSwitch}
    on:click={changeLanguage}
  >
    {activeLanguage}
  </p>

  <div class="home" class:-hidden={hideHome}>
    <h1>Memory</h1>
    <button
      class="menuBtn"
      on:click={(e) => {
        hideHome = true;
        hideCategory = false;
      }}
    >
      {translations.newGame[activeLanguage]}
    </button>
    <button
      class="menuBtn"
      class:-hidden={["/?mode=pwa", "/?mode=twa"].includes(window.location.pathname + window.location.search)}
      on:click={install}
    >
      {translations.install[activeLanguage]}
    </button>
    <button
      class="menuBtn"
      on:click={(e) => {
        hideHome = true;
        hideInfo = false;
      }}
    >
      {translations.info[activeLanguage]}
    </button>
    <div class="links">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://arabisch-lernen.net/"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="2" y1="12" x2="22" y2="12" />
          <path
            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0
            1-4-10 15.3 15.3 0 0 1 4-10z"
          />
        </svg>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://web.whatsapp.com/send?phone=4915175350931&text=Esselamu%20aleikum"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 24 24"
          stroke="none"
          fill="currentColor"
          ><path
            d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
          /></svg
        >
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/miftaahulilm/"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
          <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
          <line x1="17.5" y1="6.5" x2="17.5" y2="6.5" />
        </svg>
      </a>
    </div>
  </div>
  <div class="info" class:-hidden={hideInfo}>
    <p>v0.1.0</p>
    <p>Ein Spiel von Miftahul Ilm</p>
    <button
      class="menuBtn back"
      on:click={() => {
        hideInfo = true;
        hideHome = false;
      }}
    >
      {translations.back[activeLanguage]}
    </button>
  </div>
  <div class="category" class:-hidden={hideCategory}>
    <h1>Memory</h1>
    <button class="menuBtn" on:click={(e) => setCategory(letters)}>
      {translations.letters[activeLanguage]}
    </button>
    <button class="menuBtn" on:click={(e) => setCategory(animals)}>
      {translations.animals[activeLanguage]}
    </button>
    <button class="menuBtn" on:click={(e) => setCategory(objects)}>
      {translations.objects[activeLanguage]}
    </button>
    <button
      class="menuBtn back"
      on:click={(e) => {
        hideCategory = true;
        hideHome = false;
      }}
    >
      {translations.back[activeLanguage]}
    </button>
  </div>
  <div class="difficulty" class:-hidden={hideDifficulty}>
    <h1>Memory</h1>
    <button
      class="menuBtn"
      on:click={(e) => {
        setDifficulty(4);
        hideLanguageSwitch = true;
        hideExitGame = false;
      }}
    >
      {translations.easy[activeLanguage]}
    </button>
    <button
      class="menuBtn"
      on:click={(e) => {
        setDifficulty(6);
        hideLanguageSwitch = true;
        hideExitGame = false;
      }}
    >
      {translations.medium[activeLanguage]}
    </button>
    <button
      class="menuBtn"
      on:click={(e) => {
        setDifficulty(7);
        hideLanguageSwitch = true;
        hideExitGame = false;
      }}
    >
      {translations.hard[activeLanguage]}
    </button>
    <button
      class="menuBtn back"
      on:click={(e) => {
        hideDifficulty = true;
        hideCategory = false;
      }}
    >
      {translations.back[activeLanguage]}
    </button>
  </div>
  <div class="done" class:-hidden={hideDone}>
    <p>👍🏼 🎉</p>
    <button
      class="menuBtn"
      on:click={(e) => {
        hideDone = true;
        hideHome = false;
        hideLanguageSwitch = false;
      }}
    >
      {translations.back[activeLanguage]}
    </button>
  </div>

  {#if hideGame == false}
    <div class="game">
      <Board {category} {difficulty} on:done={handleDone} />
    </div>
  {/if}
</div>

<style>
  h1 {
    font-size: 3em;
    margin-bottom: 1rem;
    color: #3a9679;
  }
  .container {
    position: relative;
    height: 100%;
    width: 100%;
    background: #f9f8eb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    user-select: none;
  }

  .langSwitcher {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.4em;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #3a9679;
    padding: 1rem;
    cursor: pointer;
  }

  .exitGame {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.4em;
    font-weight: 700;
    color: #3a9679;
    padding: 1rem;
    cursor: pointer;
  }

  .home,
  .info,
  .category,
  .difficulty,
  .game,
  .done {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .-hidden {
    display: none;
  }

  .links {
    width: 8em;
    font-size: 1.6em;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }

  a {
    display: inline-block;
    color: #3a9679;
    text-decoration: none;
  }

  .done > p {
    font-size: 3em;
    margin-bottom: 2rem;
  }

  .info > p {
    font-size: 1.4em;
    width: 12em;
  }

  .menuBtn {
    width: 8em;
    padding: 0.6rem 1.2rem;
    margin: 0.8rem;
    font-size: 1.6em;
    font-weight: 700;
    color: white;
    background: #3a9679;
    border: none;
    border-radius: 6px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .menuBtn.back {
    margin-top: 2rem;
  }
</style>
