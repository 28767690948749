export default [
  {
    text: "بَاب",
    img: "/assets/img/door.jpg",
    audio: "/assets/audio/door.mp3"
  },
  {
    text: "مَسْجِد",
    img: "/assets/img/mosque.jpg",
    audio: "/assets/audio/mosque.mp3"
  },
  {
    text: "بَيْت",
    img: "/assets/img/house.jpg",
    audio: "/assets/audio/house.mp3"
  },
  {
    text: "كِتَاب",
    img: "/assets/img/book.jpg",
    audio: "/assets/audio/book.mp3"
  },
  {
    text: "قَلَم",
    img: "/assets/img/pen.jpg",
    audio: "/assets/audio/pen.mp3"
  },
  {
    text: "مِفْتَاح",
    img: "/assets/img/key.jpg",
    audio: "/assets/audio/key.mp3"
  },
  {
    text: "مَكْتَب",
    img: "/assets/img/table.jpg",
    audio: "/assets/audio/table.mp3"
  },
  {
    text: "سَرِير",
    img: "/assets/img/bed.jpg",
    audio: "/assets/audio/bed.mp3"
  },
  {
    text: "كُرْسِيّ",
    img: "/assets/img/chair.jpg",
    audio: "/assets/audio/chair.mp3"
  },
  {
    text: "حَجَر",
    img: "/assets/img/stone.jpg",
    audio: "/assets/audio/stone.mp3"
  },
  {
    text: "سَيّارَة",
    img: "/assets/img/car.jpg",
    audio: "/assets/audio/car.mp3"
  },
  {
    text: "مِكْوَاة",
    img: "/assets/img/iron.jpg",
    audio: "/assets/audio/iron.mp3"
  },
  {
    text: "دَرّاجَة",
    img: "/assets/img/bicycle.jpg",
    audio: "/assets/audio/bicycle.mp3"
  },
  {
    text: "سَاعَة",
    img: "/assets/img/watch.jpg",
    audio: "/assets/audio/watch.mp3"
  },
  {
    text: "مِلْعَقَة",
    img: "/assets/img/spoon.jpg",
    audio: "/assets/audio/spoon.mp3"
  },
  {
    text: "فِنْجَان",
    img: "/assets/img/mug.jpg",
    audio: "/assets/audio/mug.mp3"
  },
  {
    text: "سِكِّين",
    img: "/assets/img/knife.jpg",
    audio: "/assets/audio/knife.mp3"
  },
  {
    text: "ثَلَّاجَة",
    img: "/assets/img/fridge.jpg",
    audio: "/assets/audio/fridge.mp3"
  },
  {
    text: "شَوْكَة",
    img: "/assets/img/fork.jpg",
    audio: "/assets/audio/fork.mp3"
  },
  {
    text: "صُحْن",
    img: "/assets/img/plate.jpg",
    audio: "/assets/audio/plate.mp3"
  },
  {
    text: "كُوب",
    img: "/assets/img/cup.jpg",
    audio: "/assets/audio/cup.mp3"
  },
  {
    text: "إِبْرِيق",
    img: "/assets/img/teapot.jpg",
    audio: "/assets/audio/teapot.mp3"
  }
];
