<script>
  export let index;

  export let img;

  export let text;

  export let audio;

  export let isFlipped;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  function onClick() {
    dispatch("clicked", { index });
  }
</script>

<div class="card" class:-flipped={isFlipped} on:click={onClick}>
  <div class="card__front" />
  <div class="card__back">
    {#if img}
      <div class="back__image">
        <img src={img} alt={img} />
      </div>
    {/if}
    <div class="back__text" class:-bigLetter={!img}>
      <p>{text}</p>
    </div>
  </div>
</div>

<style>
  .card {
    position: relative;
    width: 5em;
    height: 6em;
    margin: 0.5em;
    border-radius: 6px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease-out;
    transform-style: preserve-3d;
  }

  .card:hover {
  }

  .card.-flipped {
    transform: rotateY(-180deg);
  }

  .card__front,
  .card__back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    backface-visibility: hidden;
  }

  .card__front {
    background-color: #3a9679;
    z-index: 2;
  }

  .card__back {
    transform: rotateY(180deg);
    z-index: 1;
  }

  .back__image {
    height: 60%;
  }

  .back__image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .back__text {
    height: 40%;
    padding: 0.4em;
    background: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    color: #212529;
    direction: rtl;
  }

  .back__image.-noImage {
    height: 0;
    overflow: hidden;
  }

  .back__text.-bigLetter {
    height: 100%;
    font-size: 2.5em;
  }

  @media (min-width: 60em) {
    .card {
      width: 10em;
      height: 12em;
      margin: 1em;
    }
    .back__text {
      font-size: 1.6em;
    }
  }
</style>
