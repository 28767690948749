export default [
  {
    text: "حِصَان",
    img: "/assets/img/horse.jpg",
    audio: "/assets/audio/horse.mp3"
  },
  {
    text: "أَرنَب",
    img: "/assets/img/bunny.jpg",
    audio: "/assets/audio/bunny.mp3"
  },
  {
    text: "شَاة",
    img: "/assets/img/sheep.jpg",
    audio: "/assets/audio/sheep.mp3"
  },
  {
    text: "كَلْب",
    img: "/assets/img/dog.jpg",
    audio: "/assets/audio/dog.mp3"
  },
  {
    text: "قِطَّة",
    img: "/assets/img/cat.jpg",
    audio: "/assets/audio/cat.mp3"
  },
  {
    text: "أَسَد",
    img: "/assets/img/lion.jpg",
    audio: "/assets/audio/lion.mp3"
  },
  {
    text: "فِيل",
    img: "/assets/img/elephant.jpg",
    audio: "/assets/audio/elephant.mp3"
  },
  {
    text: "سِنْجاب",
    img: "/assets/img/squirrel.jpg",
    audio: "/assets/audio/squirrel.mp3"
  },
  {
    text: "سَمَكَة",
    img: "/assets/img/fish.jpg",
    audio: "/assets/audio/fish.mp3"
  },
  {
    text: "دُودَة",
    img: "/assets/img/worm.jpg",
    audio: "/assets/audio/worm.mp3"
  },
  {
    text: "خِنْزِير",
    img: "/assets/img/pig.jpg",
    audio: "/assets/audio/pig.mp3"
  },
  {
    text: "قُنفُذ",
    img: "/assets/img/hedgehog.jpg",
    audio: "/assets/audio/hedgehog.mp3"
  },
  {
    text: "يَغْوَر",
    img: "/assets/img/jaguar.jpg",
    audio: "/assets/audio/jaguar.mp3"
  },
  {
    text: "هُدُهُد",
    img: "/assets/img/cuckoo.jpg",
    audio: "/assets/audio/cuckoo.mp3"
  },
  {
    text: "ذُبَابَة",
    img: "/assets/img/fly.jpg",
    audio: "/assets/audio/fly.mp3"
  },
  {
    text: "تِمسَاح",
    img: "/assets/img/crocodile.jpg",
    audio: "/assets/audio/crocodile.mp3"
  },
  {
    text: "طَاوُوس",
    img: "/assets/img/peafowl.jpg",
    audio: "/assets/audio/peafowl.mp3"
  },
  {
    text: "ثُعْبَان",
    img: "/assets/img/snake.jpg",
    audio: "/assets/audio/snake.mp3"
  },
  {
    text: "جَمَل",
    img: "/assets/img/camel.jpg",
    audio: "/assets/audio/camel.mp3"
  },
  {
    text: "سُلْحُفَاة",
    img: "/assets/img/tortoise.jpg",
    audio: "/assets/audio/tortoise.mp3"
  },
  {
    text: "زَرَافَة",
    img: "/assets/img/giraffe.jpg",
    audio: "/assets/audio/giraffe.mp3"
  },
  {
    text: "حُوت",
    img: "/assets/img/wale.jpg",
    audio: "/assets/audio/wale.mp3"
  },
  {
    text: "وَحِيدُ الْقَرْن",
    img: "/assets/img/rhinoceros.jpg",
    audio: "/assets/audio/rhinoceros.mp3"
  },
  {
    text: "نَحْلَة",
    img: "/assets/img/bee.jpg",
    audio: "/assets/audio/bee.mp3"
  },
  {
    text: "قُنْدُس",
    img: "/assets/img/beaver.jpg",
    audio: "/assets/audio/beaver.mp3"
  },
  {
    text: "كَنْغَر",
    img: "/assets/img/kangaroo.jpg",
    audio: "/assets/audio/kangaroo.mp3"
  },
  {
    text: "غُرَاب",
    img: "/assets/img/bird.jpg",
    audio: "/assets/audio/bird.mp3"
  },
  {
    text: "بَقَرَة",
    img: "/assets/img/cow.jpg",
    audio: "/assets/audio/cow.mp3"
  },
  {
    text: "دَجَّاجَة",
    img: "/assets/img/chicken.jpg",
    audio: "/assets/audio/chicken.mp3"
  }
]