export default [
  { text: "ء", audio: "/assets/audio/hamza.mp3" },
  { text: "ﺍ", audio: "/assets/audio/alif.mp3" },
  { text: "ﺏ", audio: "/assets/audio/ba.mp3" },
  { text: "ﺕ", audio: "/assets/audio/ta.mp3" },
  { text: "ﺙ", audio: "/assets/audio/tha.mp3" },
  { text: "ﺝ", audio: "/assets/audio/geem.mp3" },
  { text: "ﺡ", audio: "/assets/audio/hha.mp3" },
  { text: "ﺥ", audio: "/assets/audio/cha.mp3" },
  { text: "ﺩ", audio: "/assets/audio/dal.mp3" },
  { text: "ﺫ", audio: "/assets/audio/dhal.mp3" },
  { text: "ﺭ", audio: "/assets/audio/ra.mp3" },
  { text: "ﺯ", audio: "/assets/audio/zay.mp3" },
  { text: "ﺱ", audio: "/assets/audio/sin.mp3" },
  { text: "ﺵ", audio: "/assets/audio/shin.mp3" },
  { text: "ﺹ", audio: "/assets/audio/sad.mp3" },
  { text: "ﺽ", audio: "/assets/audio/shad.mp3" },
  { text: "ﻁ", audio: "/assets/audio/tta.mp3" },
  { text: "ﻅ", audio: "/assets/audio/ttha.mp3" },
  { text: "ﻉ", audio: "/assets/audio/ayn.mp3" },
  { text: "ﻍ", audio: "/assets/audio/hayn.mp3" },
  { text: "ﻑ", audio: "/assets/audio/fa.mp3" },
  { text: "ﻕ", audio: "/assets/audio/qaf.mp3" },
  { text: "ﻙ", audio: "/assets/audio/kaf.mp3" },
  { text: "ﻝ", audio: "/assets/audio/lam.mp3" },
  { text: "ﻡ", audio: "/assets/audio/mim.mp3" },
  { text: "ﻥ", audio: "/assets/audio/nun.mp3" },
  { text: "ﻩ", audio: "/assets/audio/ha.mp3" },
  { text: "ﻭ", audio: "/assets/audio/waw.mp3" },
  { text: "ي", audio: "/assets/audio/ya.mp3" }
];
